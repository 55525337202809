<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Добавление модели
            </h2>
          </el-col>
        </el-row>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          @submit="submit"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="name" label="Название">
                <el-input
                  autofocus
                  size="small"
                  v-model="form.name"
                  placeholder="Введите название"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="main_stream_template" label="MAIN поток">
                <el-input
                  size="small"
                  v-model="form.main_stream_template"
                  placeholder="Введите шаблон основного потока"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="main_stream_resolution" label="MAIN разрешение">
                <el-input
                  class="bandwidth"
                  size="small"
                  placeholder="Ширина"
                  v-model="bandwidth.mainWidth"
                  @input="concatMainBandwidth"
                />
                x
                <el-input
                  class="bandwidth"
                  size="small"
                  placeholder="Высота"
                  v-model="bandwidth.mainHeight"
                  @input="concatMainBandwidth"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="main_stream_bandwidth" label="MAIN битрейт">
                <el-input-number
                  :controls="false"
                  size="small"
                  v-model="form.main_stream_bandwidth"
                  placeholder="Введите битрейт основного потока"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="Наличие SUB потока">
                <el-switch
                  v-model="needSubStream"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="needSubStream">
            <el-col :span="12">
              <el-form-item
                prop="sub_stream_template"
                label="SUB поток"
              >
                <el-input
                  size="small"
                  v-model="form.sub_stream_template"
                  placeholder="Введите шаблон дополнительного потока"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="needSubStream">
            <el-col :span="12">
              <el-form-item
                prop="sub_stream_resolution"
                label="SUB разрешение"
              >
                <el-input
                  class="bandwidth"
                  size="small"
                  placeholder="Ширина"
                  v-model="bandwidth.subWidth"
                  @input="concatSubBandwidth"
                />
                x
                <el-input
                  class="bandwidth"
                  size="small"
                  placeholder="Высота"
                  v-model="bandwidth.subHeight"
                  @input="concatSubBandwidth"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item prop="transcode_video" label="Транскодинг видео">
                <el-switch
                  v-model="form.transcode_video"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="transcode_audio" label="Транскодинг аудио">
                <el-switch
                  v-model="form.transcode_audio"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="id_sidetask_setting" label="Тип side-task`а">
                <select-model-side-task-type
                  :selected="form.id_sidetask_setting"
                  @change="onSidetaskChange"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :guter="20" v-if="form.has_custom_sound_settings">
            <el-col :span="12">
              <el-form-item
                prop="custom_sound_settings"
                label="Кастомные опции FFMPEG"
              >
                <el-input
                  v-model="form.custom_sound_settings"
                  size="small"
                  placeholder="Введите кастомные опции транскодирования аудио-потока"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item prop="is_onvif" label="ONVIF">
                <el-switch
                  v-model="form.is_onvif"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="is_ptz" label="PTZ">
                <el-switch
                  v-model="form.is_ptz"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item prop="is_wifi" label="Wi-Fi">
                <el-switch
                  v-model="form.is_wifi"
                  :active-value="1"
                  :inactive-value="0"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="port_onvif_default" label="Порт ONVIF по умолчанию" v-if="form.is_onvif == 1">
                <el-input-number
                  :controls="false"
                  class="portonvifinput"
                  size="small"
                  v-model="form.port_onvif_default"
                  placeholder="Введите порт"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                prop="url_onvif_template"
                label="ONVIF URL"
                v-if="form.is_onvif == 1"
              >
                <el-input
                  size="small"
                  v-model="form.url_onvif_template"
                  placeholder="Введите шаблон url для ONVIF соединения"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="Подключение с серийным номером, подходящим под маску">
                <el-switch
                  v-model="needSerialNumberMask"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="needSerialNumberMask">
            <el-col :span="12">
              <el-form-item
                prop="serial_number_mask"
                label="Маска серийника"
              >
                <el-input
                  size="small"
                  v-model="form.serial_number_mask"
                  placeholder="Маска, с которой может начинаться серийник камер данной модели"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item prop="description" label="Описание">
                <el-input
                  autosize
                  type="textarea"
                  v-model="form.description"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button
        id="addModel"
        class="page__active_button"
        type="primary"
        @click="submit"
      >Сохранить
      </el-button>
    </el-footer>
  </div>
</template>

<script>
import form from "../mixins/form";
import { mapActions } from "vuex";
import SelectModelSideTaskType from "@/core/components/SelectModelSidetask";

export default {
  name: "ModelFormCreate",
  components: {
    SelectModelSideTaskType,
  },
  mixins: [form],
  data() {
    return {
      needSubStream: false,
      needSerialNumberMask: false,
    };
  },
  methods: {
    onSidetaskChange(setting) {
      this.form.id_sidetask_setting = setting.id_sidetask_setting;
      this.form.has_custom_sound_settings = setting.has_custom_sound_settings;
      this.form.custom_sound_settings = setting.default_sound_settings;
    },
    submit(event) {
      event.preventDefault();

      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loading = true;

          this.addModel(this.form)
            .then(id => {
              this.$message({
                message: 'Модель добавлена',
                type: 'success'
              });
              this.$router.push({ name: 'models:edit', params: { id } });
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: e.response.data.error || "Неизвестная ошибка",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }

        return valid;
      });
    },
    ...mapActions('models', {
      addModel: 'ADD_MODEL'
    }),
  },
};
</script>

<style lang="postcss">
.page_container {
  padding: 20px;
}
.bandwidth, .portonvifinput {
  width: 120px;
  padding: 0 10px
}
.bandwidth, .portonvifinput > input {
  text-align: center;
}
</style>
